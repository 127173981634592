// LoginMedVipps.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginMedVipps.css'; // Optional: Add styling for this component

const LoginMedVipps = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        // Validate phoneNumber and pinCode
        if (!phoneNumber || !pinCode || pinCode.length !== 4) {
            setErrorMessage('Vennligst skriv inn et gyldig mobilnummer og en PIN.');
            return;
        }

        try {

            const response = await fetch('https://backend-dot-hai1-437508.ew.r.appspot.com/api/login_with_pin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    phone_number: phoneNumber,
                    pin_code: pinCode
                }),
            });

            const data = await response.json();

            if (response.ok && data.access_token) {
                // Store the access token
                localStorage.setItem('access_token', data.access_token);

                // Navigate to the protected chat window
                navigate('/chat');
            } else {
                // Show error message from backend if available, else default
                setErrorMessage(data.error || 'Feil telefonnummer eller PIN. Vennligst prøv igjen.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setErrorMessage('En feil oppstod under innloggingen. Vennligst prøv igjen senere.');
        }
    };

    return (
        <div className="login-vipps-container">
            <h1>Logg inn med PIN</h1>
            <p>Skriv inn ditt mobilnummer og din kode.</p>

            <div className="input-container">
                <label htmlFor="phoneNumber">Mobilnummer: </label>
                <input
                    type="text"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Skriv inn mobilnummer"
                />
            </div>

            <div className="input-container">
                <label htmlFor="pinCode">PIN: </label>
                <input
                    type="password"
                    id="pinCode"
                    maxLength={12}
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    placeholder="Skriv inn din PIN-kode"
                />
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button className="login-button" onClick={handleLogin}>
                Logg inn
            </button>
        </div>
    );
};

export default LoginMedVipps;





