// src/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css'; // Make sure this file exists for styling
import { useNavigate } from 'react-router-dom';

const Chat = () => {
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const chatWindowRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const verifySubscription = async () => {
            const token = localStorage.getItem('access_token');
            if (!token) {
                console.warn('No access token found. Redirecting to home.');
                navigate('/');
                return;
            }

            console.log('Verifying subscription with https://backend-dot-hai1-437508.ew.r.appspot.com/api/protected-chat...');
            try {
                const response = await fetch('https://backend-dot-hai1-437508.ew.r.appspot.com/api/protected-chat', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (response.status === 403 || response.status === 401) {
                    console.warn('Access denied or subscription expired. Redirecting to home.');
                    alert('Access denied. Please subscribe to use the chat.');
                    navigate('/');
                } else if (!response.ok) {
                    console.error('Error verifying subscription:', response.status, response.statusText);
                    alert('En feil oppstod. Prøv igjen senere.');
                    navigate('/');
                } else {
                    console.log('Subscription verified successfully. User can chat now.');
                }
            } catch (error) {
                console.error('Error verifying subscription:', error);
                alert('En feil oppstod. Prøv igjen senere.');
                navigate('/');
            }
        };

        verifySubscription();
    }, [navigate]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [responses, isLoading]);

    const sendMessage = async () => {
        const trimmedMessage = message.trim();
        if (!trimmedMessage) return;

        setResponses(prevResponses => [...prevResponses, { sender: 'Jeg', text: trimmedMessage }]);
        setMessage('');
        setIsLoading(true);

        console.log('Sending message:', trimmedMessage);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post('https://backend-dot-hai1-437508.ew.r.appspot.com/api/chat-message', { message: trimmedMessage }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('Response received from https://backend-dot-hai1-437508.ew.r.appspot.com/api/chat-message:', response.data);
            setResponses(prevResponses => [...prevResponses, { sender: 'HAI', text: response.data.response }]);
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMsg = error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : 'Beklager, noe gikk galt.';
            setResponses(prevResponses => [...prevResponses, { sender: 'HAI', text: errorMsg }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="chat-container">
            <div className="icon"></div> {/* Add an icon here if needed */}
            <h1>HAI - Heste AI</h1>
            <div className="chat-window" ref={chatWindowRef}>
                {responses.map((resp, index) => (
                    <div key={index} className={`message ${resp.sender === 'HAI' ? 'HAI' : 'Jeg'}`}>
                        <strong>{resp.sender}:</strong> {resp.sender === 'HAI' ? renderTextWithLineBreaks(resp.text) : resp.text}
                    </div>
                ))}
                {isLoading && <div className="message HAI"><strong>HAI:</strong> Skriver...</div>}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Spør meg om hestetips..."
                    className="message-input"
                />
                <button onClick={sendMessage} className="send-button">Send</button>
            </div>
        </div>
    );
};

export default Chat;




