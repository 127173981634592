/// src/components/VippsCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const VippsCallback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isProcessing, setIsProcessing] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const handleCallback = async () => {
            const queryParams = new URLSearchParams(location.search);
            const orderId = queryParams.get('order_id'); // Avhengig av Vipps-konfigurasjon

            if (orderId) {
                try {
                    // Finn user_id fra localStorage
                    const userId = localStorage.getItem('user_id');
                    const mobileNumber = localStorage.getItem('mobile_number') || '12345678'; // Sett riktig mobilnummer

                    if (!userId) {
                        setError('Ingen bruker-ID funnet. Vennligst logg inn på nytt.');
                        setIsProcessing(false);
                        return;
                    }

                    // Send login forespørsel for å få JWT-token
                    const response = await fetch('/api/login', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ user_id: userId, mobile_number: mobileNumber }),
                    });

                    const data = await response.json();
                    if (data.access_token) {
                        // Lagre token
                        localStorage.setItem('access_token', data.access_token);
                        // Redirect til chat
                        navigate('/chat');
                    } else {
                        setError('Login failed. Vennligst prøv igjen.');
                        setIsProcessing(false);
                    }
                } catch (error) {
                    console.error('Error during login:', error);
                    setError('En feil oppsto under login. Vennligst prøv igjen.');
                    setIsProcessing(false);
                }
            } else {
                setError('Ingen order ID funnet. Vennligst prøv igjen.');
                setIsProcessing(false);
            }
        };

        handleCallback();
    }, [location, navigate]);

    if (isProcessing) {
        return (
            <div>
                <h2>Behandler betaling...</h2>
            </div>
        );
    }

    return (
        <div>
            <h2>{error}</h2>
            <button onClick={() => navigate('/')}>Tilbake til Login</button>
        </div>
    );
};

export default VippsCallback;










