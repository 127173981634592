// src/components/Login.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Sørg for at du har styles for denne komponenten
import { v4 as uuidv4 } from 'uuid';

const Login = () => {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [userId, setUserId] = useState('');

    // Generer en unik bruker-ID hvis den ikke allerede finnes i localStorage
    useEffect(() => {
        let storedUserId = localStorage.getItem('user_id');
        if (!storedUserId) {
            storedUserId = uuidv4();
            localStorage.setItem('user_id', storedUserId);
        }
        setUserId(storedUserId);
    }, []);

    // Sjekk om brukeren allerede har et gyldig abonnement
    const checkUserSubscription = async () => {
        try {
            const storedUserId = localStorage.getItem('user_id');
            const response = await fetch(`/api/check-subscription?user_id=${storedUserId}`, {
                method: 'GET',
                credentials: 'include',
            });

            const data = await response.json();
            if (data.has_valid_subscription) {
                navigate('/chat');
            }
        } catch (error) {
            console.error('Error checking subscription:', error);
            // Hvis det oppstår en feil, tillat normal login flyt
        }
    };

    // Kjør sjekken ved komponent-mount
    useEffect(() => {
        checkUserSubscription();
    }, []);

    // Handler for Vipps Payment
    const handleVippsPayment = async () => {
        if (!mobileNumber) {
            alert('Vennligst oppgi ditt mobilnummer.');
            return;
        }

        try {
            const response = await fetch('/api/vipps-payment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: userId, mobile_number: mobileNumber }),
            });
            const data = await response.json();

            if (data.vipps_url) {
                window.location.href = data.vipps_url; // Redirect til Vipps betalingsside
            } else {
                alert('Kunne ikke starte betaling. Prøv igjen senere.');
            }
        } catch (error) {
            console.error('Error initiating Vipps payment:', error);
            alert('En feil oppsto under betaling. Vennligst prøv igjen.');
        }
    };

    return (
        <div className="login-container">
            <h1>Velkommen til HAIen</h1>
            <p>Din personlige assistent.</p>

            {/* Input for mobilnummer
            <div className="input-group">
                <label htmlFor="mobile">Mobilnummer: </label>
                <input
                    type="text"
                    id="mobile"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="12345678"
                />
            </div> */}

            <div className="button-group">
                {/*<div className="vipps-section">
                    <p>Ny kunde:</p>
                    <button className="vipps-button" onClick={handleVippsPayment}>
                        Betal med Vipps
                    </button>
                </div> */}
                <div className="vipps-section">
                    {/*<p>Eksisterende kunde:</p>*/}
                    <button
                        className="vipps-button"
                        onClick={() => navigate('/login-med-vipps')}
                    >
                        Login
                    </button>
                </div>
            </div>

            {/* Forklaring */}
            <div className="info-section">
                <h2>Hvem er HAIen?</h2>
                <p>
                    HAIen er en kunstig intelligens som blant annet kan analysere travløp. Den svarer på spørsmål, gir tips
                    til vinnere, trippler og kan kanskje plukke ut en skrell. Selv om den ikke har fasit, er den en god
                    assistent når du setter opp bonger. HAIen forstår og snakker de fleste språk og er tilgjengelig 24/7.
                </p>
            </div>

            {/* Embeddet video */}
            <div className="video-section">
                <video
                    src={`/hai_demo.mov`}
                    controls
                    autoPlay
                    loop
                    muted
                    className="embedded-video"
                ></video>
            </div>

            {/* Lenker */}
            <div className="footer-links">
                <a href="/tos.html" target="_blank" rel="noopener noreferrer">
                    Terms of Sales
                </a>{' '}
                |{' '}
                <a href="/personvern.html" target="_blank" rel="noopener noreferrer">
                    Personvern
                </a>
            </div>
        </div>
    );
};

export default Login;









