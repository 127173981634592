// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Chat from './Chat';
import LoginMedVipps from './components/LoginMedVipps';
import VippsCallback from './components/VippsCallback'; // Importer VippsCallback

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/login-med-vipps" element={<LoginMedVipps />} />
                <Route path="/vipps-callback" element={<VippsCallback />} /> {/* Legg til VippsCallback ruten */}
            </Routes>
        </Router>
    );
}

export default App;

